import React, { useContext, useEffect, useState } from "react";
import { message, Button, Layout, Row, Col, Typography } from "antd"
import ImpersonationBanner from "../components/impersonationBanner"
import ViewBlackoutDrawer from "../components/blackout_ViewDrawer"
import CreateBlackoutDrawer from "../components/blackout_CreateDrawer"
import { GlobalStateContext, GlobalDispatchContext } from "../context/GlobalContextProvider"
import BlackoutCard from "../components/blackout_Card";
import LoadingPage from "../components/loadingPage"
import { navigate } from "gatsby";
import moment from "moment-timezone";
moment.tz.setDefault('Australia/Sydney');

const { Header, Content } = Layout;
const { Title } = Typography;

const Blackouts = () => {

  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const [selectedBlackout, setSelectedBlackout] = useState({});
  const [viewBlackoutDrawerVisible, setViewBlackoutDrawerVisible] = useState(false);
  const [createBlackoutDrawerVisible, setCreateBlackoutDrawerVisible] = useState(false);

  // DATA MANAGEMENT
  useEffect(() => {
    if(state.auth_state)
      dispatch({ type:'ROUTE', route: "/blackouts"})
  }, [state.auth_state, dispatch])

  const handleRouting = () => {
    if(state.role_level > 0 && !state.profile)
      navigate('/users')
  }
  useEffect(handleRouting, [state.role_level, state.profile, navigate]);

  const refreshList = () => {
    dispatch({ type:'REFRESH', toRefresh: 'refreshBlackouts'});
  }

  // RENDER FUNCTIONS
  const renderBlackoutCards = () => {
    console.log("Blackout cards")
    if(state.blackoutsLoaded){
      let cards = []
      state.blackoutsData.forEach((blackout, index) => {
        cards.push(<Col key={index} span={24}><BlackoutCard blackout={blackout} onClick={() => showViewBlackoutDrawer(blackout)}/></Col>)
      })
      console.log("CARDS LENGTH", cards.length)
      if(cards.length === 0){
        cards.push(<div key={"none"}>No blackouts for this experience</div>)
      }
      return cards
    }
  }

  const blackoutCreated = (response) => {
    message.info(response);
    closeCreateBlackoutDrawer();
    refreshList();
  }

  const handleBlackoutDeleted = () => {
    closeViewBlackoutDrawer();
    refreshList();
  }

  const showViewBlackoutDrawer = (blackout, experience_data) => {
    setSelectedBlackout(blackout)
    setViewBlackoutDrawerVisible(true);
  };

  const closeViewBlackoutDrawer = () => {
    setViewBlackoutDrawerVisible(false);
  };

  const showCreateBlackoutDrawer = () => {
    setCreateBlackoutDrawerVisible(true);
  };

  const closeCreateBlackoutDrawer = () => {
    setCreateBlackoutDrawerVisible(false);
  }

  const handleExitImpersonation = () => {
    dispatch({ type:'IMPERSONATE', profile: "", imp_username: "" });
    navigate('/users')
  }

  return(
    <div id="blackouts-page" className="page">
      <ImpersonationBanner role_level={state.role_level} username={state.imp_username} profile={state.profile} onExit={handleExitImpersonation}/>
      <Header id="header">
        <Row className="main-row">
          <Col className="title" span={6}>
            <Title>Blackouts</Title>
          </Col>
          <Col flex="auto">
            <Row gutter={12} justify="end" width="600px">
              <Col className="create-blackout" span={8}>
                <Button block type="primary" onClick={showCreateBlackoutDrawer}>Create Blackout</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="lower-row" justify="start" gutter={12}>
        </Row>
      </Header>
      <Content>
        <LoadingPage className={state.blackoutsLoaded && state.experiencesLoaded ? "loaded" : "loading" } />
        <Row className="content-container" gutter={[0, 20]}>
          {renderBlackoutCards()}
        </Row>
      </Content>
      <ViewBlackoutDrawer blackout={selectedBlackout} visible={viewBlackoutDrawerVisible} onDelete={handleBlackoutDeleted} onClose={closeViewBlackoutDrawer}/>
      <CreateBlackoutDrawer visible={createBlackoutDrawerVisible} onSuccess={blackoutCreated} onClose={closeCreateBlackoutDrawer}/>
    </div>
  )
}

export default Blackouts;
