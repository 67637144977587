import React, { useContext, useState } from "react"
import { message, Button, Row, Col, Drawer, Form, Select, Divider, Space, Typography, DatePicker } from "antd"
import Icon from '@ant-design/icons';
import { APICreateBlackout } from '../scripts/blackouts'
import { GlobalStateContext } from "../context/GlobalContextProvider"
import blackouts_icon_svg from '../assets/svgs/blackouts_icon.svg'
import moment from "moment-timezone";
moment.tz.setDefault('Australia/Sydney');

const { Option } = Select;
const { Title } = Typography;
const { RangePicker } = DatePicker;

const BlackoutsIcon = props => <Icon className="wtc-icon blackouts-icon" component={blackouts_icon_svg} {...props} />;

const CreateBlackoutDrawer = (props) => {
  // ===========================
  // Variables
  // ===========================
  const state = useContext(GlobalStateContext)

  //  API Request messages
  const [loading, setLoading] = useState(false)

  // Form 
  const [form] = Form.useForm();

  // ===========================
  // API REQUESTS
  // ===========================
  const createBlackout = async data => {
    console.log(data)
    let products_array = data.experiences.map(experience => {
      return { "external_product_id": experience }
    })
    let create_blackout_request = {
      products: products_array,
      starts_at: data.date_range[0].format("YYYY-MM-DDTHH:mm:ssZ"),
      finishes_at: data.date_range[1].format("YYYY-MM-DDTHH:mm:ssZ"),
      scope: 0,
      all_day: true
    }

    console.log(create_blackout_request)

    setLoading(true)
    await APICreateBlackout(create_blackout_request)
      .then(response => {
        setLoading(false)
        console.log(response)
        if (response.data.statusCode === 200) {
          console.log(response)
          setLoading(false)
          form.resetFields();
          props.onSuccess("Blackout created!");
        }
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
        if (error.type === "validation") {
          message.error("Error: No User deleted")
        }
        else {
          message.error("An error occured!")
        }
      })

  }

  // ===========================
  // Component Functions
  // ===========================

  const renderExperienceOptions = () => {
    if (state.experiencesLoaded && state.experiencesData) {
      return state.experiencesData.map((experience, index) => {
        return <Option key={index} value={experience.external_id}>{experience.title}</Option>
      })
    }
    else {
      return <Option>Loading...</Option>
    }
  }

  const handleClose = () => {
    if (form)
      form.resetFields();
    props.onClose()
  }

  return (
    <Drawer
      id={"create-blackout"}
      width={580}
      onClose={handleClose}
      visible={props.visible}
      bodyStyle={{ padding: "40px 40px" }}
    >
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Space align="center" size={20}>
            <BlackoutsIcon style={{ fontSize: "40px" }} />
            <Title level={2}>Create a new blackout</Title>
          </Space>
        </Col>
        <Col span={24}>
          <Divider style={{ margin: 0 }} />
        </Col>
      </Row>
      <Form layout="horizontal" form={form} hideRequiredMark wrapperCol={24} mode={"month"} onFinish={event => createBlackout(event)}>
        <Form.Item
          name="experiences"
          label="1. Select experiences"
          labelAlign="left"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          hasFeedback
          rules={[{ required: true, message: 'Please select an experience' }]}
        >
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Please select experiences">
            {renderExperienceOptions()}
          </Select>
        </Form.Item>
        <Form.Item
          name="date_range"
          label="2. Select a date range"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          labelAlign="left"
          hasFeedback
          rules={[{ required: true, message: 'Please select a date range' }]}>
          <RangePicker
            showToday={false}
            allowClear={true}
            format={['DD/MM/YYYY', 'DD/MM/YY']}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item>
          <Row gutter={10} align="bottom">
            <Col span={12}>
              <Button loading={loading} size={"large"} block type="primary" htmlType="submit" >
                Create blackout
              </Button>
            </Col>
            <Col offset={6} span={6}>
              <Button type="text" onClick={handleClose} block>
                Cancel
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

CreateBlackoutDrawer.defaultProps = {
  onSuccess: () => { }
}

export default CreateBlackoutDrawer


