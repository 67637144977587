import React, { useContext }  from "react"
import { Card, Divider, Typography, Space, Badge } from "antd"
import { GlobalStateContext } from "../context/GlobalContextProvider"

import moment from "moment-timezone";
moment.tz.setDefault('Australia/Sydney');

const { Text, Title } = Typography;

const BlackoutCard = (props) => {
  const state = useContext(GlobalStateContext)

  const createdTimeCompare = () => {
    let difference = moment().diff(props.blackout.created_at, 'minutes')
    if(difference <= 60){ return "inherit" }
    return "none"
  }

  const renderExperienceTitles = () => {
    let titles = [];
    if(props.blackout.products.length !== 0 && state.experiencesLoaded){
      props.blackout.products.forEach((product, index) => {
        state.experiencesData.forEach(experience => {
          if(product.external_product_id === experience.external_id){
            titles.push(experience.title)
          }
        })
      })
    }
    return titles.join(", ");
  }

  return (
    <Badge.Ribbon style={{display: createdTimeCompare()}} text="New" color="#ffdb78">
      <Card className="blackout-card" onClick={props.onClick}>  
        <Space direction={"vertical"} space={10}>
          <Title level={4}>{moment(props.blackout.starts_at).format("Do MMMM YYYY")} — {moment(props.blackout.finishes_at).format("Do MMMM YYYY")}</Title>
          <Divider style={{margin: "5px"}}/>
          <Text>{renderExperienceTitles()}</Text>
        </Space>
      </Card>
    </Badge.Ribbon>
   )
}

export default BlackoutCard