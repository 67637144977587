import React, { useEffect, useState, useContext } from "react"
import { message, Row, Col, Drawer, Divider, Button, Popover, Typography, Space, Form, DatePicker, Select } from "antd"
import Icon from '@ant-design/icons';
import { APIDeleteBlackout, APIUpdateBlackout } from "../scripts/blackouts"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"
import blackouts_icon_svg from '../assets/svgs/blackouts_icon.svg'
import moment from "moment-timezone";
moment.tz.setDefault('Australia/Sydney');

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const BlackoutsIcon = props => <Icon className="wtc-icon blackouts-icon" component={blackouts_icon_svg} {...props} />;

const ViewBlackoutDrawer = (props) => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [form] = Form.useForm();

  const deleteBlackout = async () => {
    setDeleteLoading(true)
    await APIDeleteBlackout(props.blackout.id)
      .then(response => {
        setDeleteLoading(false)
        console.log(response)
        if (response.data.statusCode === 200) {
          console.log(response)
          dispatch({ type: 'REFRESH', toRefresh: 'refreshBlackouts' });
          message.info("Blackout deleted!")
          props.onDelete("Blackout deleted");
        }
      })
      .catch(error => {
        console.error(error)
        setDeleteLoading(false)
        if (error.type === "validation") {
          message.error("Error: No User deleted")
        }
        else {
          message.error("An error occured!")
        }
      })
  }

  const updateBlackout = async (data) => {

    console.log(data)
    let products_array = data.experiences.map(experience => {
      return { "external_product_id": experience }
    })
    let update_blackout_request = {
      blackout_id: props.blackout.id,
      products: products_array,
      starts_at: data.date_range[0].format("YYYY-MM-DDTHH:mm:ssZ"),
      finishes_at: data.date_range[1].format("YYYY-MM-DDTHH:mm:ssZ"),
      scope: 0,
      all_day: true
    }

    console.log(update_blackout_request)
    setUpdateLoading(true)
    await APIUpdateBlackout(update_blackout_request)
      .then(response => {
        setUpdateLoading(false)
        console.log(response)
        if (response.data.statusCode === 200) {
          console.log(response)
          dispatch({ type: 'REFRESH', toRefresh: 'refreshBlackouts' });
          message.info("Blackout updated!")
          props.onDelete("Blackout updated");
        }
      })
      .catch(error => {
        console.error(error)
        setUpdateLoading(false)
        if (error.type === "validation") {
          message.error("Error: No User updated")
        }
        else {
          message.error("An error occured!")
        }
      })
  }

  const renderExperienceOptions = () => {
    if (state.experiencesLoaded && state.experiencesData) {
      return state.experiencesData.map((experience, index) => {
        return <Option key={index} value={experience.external_id}>{experience.title}</Option>
      })
    }
    else {
      return <Option>Loading...</Option>
    }
  }

  const renderExperiences = () => {
    let titles = [];
    if (props.blackout && state.experiencesLoaded) {
      if (props.blackout.products) {
        props.blackout.products.forEach((product, index) => {
          state.experiencesData.forEach(experience => {
            if (product.external_product_id === experience.external_id) {
              titles.push(experience.title)
            }
          })
        })
      }
    }
    return titles.join(", ");
  }

  const getDefaultValues = () => {
    let experiences = [];
    if (props.blackout && state.experiencesLoaded) {
      if (props.blackout.products) {
        props.blackout.products.forEach((product, index) => {
          experiences.push(product.external_product_id)
        })
      }
    }
    console.log(experiences)
    return experiences;
  }

  const handleBlackoutChange = () => {
    if (props.blackout) {
      if (form) {
        onReset();
      }
    }
  }
  useEffect(handleBlackoutChange, [props.blackout]);

  const onReset = () => {
    let form_data = {
      experiences: getDefaultValues(),
      date_range: [moment(props.blackout.starts_at), moment(props.blackout.finishes_at)]
    }
    console.log(form_data)
    form.setFieldsValue(form_data)
  }

  useEffect(() => {
    if (props.visible === false) {
      setPopoverVisible(false)
    }
  }, [props.visible]);

  return (
    <Drawer
      width={580}
      onClose={props.onClose}
      visible={props.visible}
      bodyStyle={{ padding: 80 }}
      className="view-blackout-drawer"
    >
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Space align="center" size={20}>
            <BlackoutsIcon style={{ fontSize: "40px" }} />
            <Title level={3}>{moment(props.blackout.starts_at).format("Do MMMM YYYY")} — <br />{moment(props.blackout.finishes_at).format("Do MMMM YYYY")}</Title>
          </Space>
        </Col>
        <Col span={24}>
          <Divider style={{ margin: 0 }} />
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Title style={{ marginBottom: "10px" }} level={3}>Experiences</Title>
          <Title type="secondary" className="text-light" style={{ marginBottom: "0px" }} level={4}>
            {renderExperiences()}
          </Title>
          {/* <Descriptions size="small" column={1}> */}
          {/* {renderExperiences()} */}
          {/* </Descriptions> */}
        </Col>
        <Col span={24}>
          <Divider style={{ margin: "0" }} />
        </Col>
        <Col span={24}>
          <Title style={{ marginBottom: "0px" }} level={4}>Edit Blackout</Title>
        </Col>
      </Row>
      <Form layout="horizontal" form={form} hideRequiredMark wrapperCol={24} mode={"month"} onFinish={event => updateBlackout(event)}>
        <Form.Item
          name="experiences"
          label="1. Select experiences"
          labelAlign="left"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={getDefaultValues()}
          hasFeedback
          rules={[{ required: true, message: 'Please select an experience' }]}
        >
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Please select experiences">
            {renderExperienceOptions()}
          </Select>
        </Form.Item>
        <Form.Item
          name="date_range"
          label="2. Select a date range"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          labelAlign="left"
          hasFeedback
          rules={[{ required: true, message: 'Please select a date range' }]}>
          <RangePicker
            showToday={false}
            allowClear={true}
            format={['DD/MM/YYYY', 'DD/MM/YY']}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item>
          <Row gutter={10} align="bottom" style={{ marginTop: "10px" }}>
            <Col span={12}>
              <Button loading={updateLoading} size={"large"} block type="primary" htmlType="submit" >
                Save
              </Button>
            </Col>
            <Col offset={6} span={6}>
              <Popover
                visible={popoverVisible}
                onVisibleChange={visible => setPopoverVisible(props.visible ? visible : false)}
                content={<Space size={10}>Are you sure?<Button type="primary" loading={deleteLoading} danger onClick={deleteBlackout}>YES</Button></Space>}
                trigger={"click"}>
                <Button danger>
                  Delete blackout
                </Button>
              </Popover>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Drawer>
  )
}
export default ViewBlackoutDrawer

ViewBlackoutDrawer.defaultProps = {
  blackout: {}
}

